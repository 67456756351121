import React, { FunctionComponent, useState, useEffect, useContext, useRef } from "react"
import { globalHistory } from "@reach/router"

import "./styles.scss"

import Burger from "~/vectors/burger.inline.svg"
import Close from "~/vectors/close.inline.svg"
import { Link } from "gatsby"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import { MenuItem } from "./MenuItem"
import { forYourBusiness, forYourHome, forYourCommunity, company, solarTogether, infoCenter, products } from "./config"

interface BurgerMenuProps {
  className?: string
}

const BurgerMenu: FunctionComponent<BurgerMenuProps> = ({
  className,
  children,
}) => {
  const [open, setOpen] = useState(false)

  const { customerType, setCustomerType } = useContext(CustomerTypeContext);

  const isDomestic = React.useMemo(() => customerType === "domestic", [customerType]);
  const isBusiness = React.useMemo(() => customerType === "commercial", [customerType]);
  const isSolarTogether = React.useMemo(() => customerType === "solartogether", [customerType]);


  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setOpen(false)
      }
    })
  }, [])

  const toggleOpen = () => {
    setOpen(!open)
  }

  const toggleCollapse = (e: any) => {
    e.preventDefault();

    const menuItem = e.currentTarget;

    const children = menuItem.children;
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      ////console.log(child);
      if (child.classList.contains('active')) {
        child.classList.remove('active');
        continue;
      }
      child.classList.add('active');
    }
  }

  const toggleCollapseWithSubMenu = (e: any) => {
    
  }

  return (

    
    <div
      className={`burger-menu ${className} ${open ? "burger-menu--open" : ""}`}
    >
      <div className="burger-menu__toggle" onClick={toggleOpen}>
        {open && <Close white />}
        {!open && <Burger />}
      </div>

      {/*Start domestic menu*/}
      {isDomestic && (
      <div className="burger-menu__items"> 
        <div className="scrollarea">
          <MenuItem  menuData={forYourHome} />
          <MenuItem menuData={forYourBusiness} /> 
          <MenuItem menuData={forYourCommunity} /> 
          <MenuItem menuData={solarTogether} /> 
          <MenuItem menuData={company} />
          <MenuItem menuData={products} />
          <MenuItem menuData={infoCenter} />       
        </div>
        <div className="navigation-item navigation-item--shout">
          <Link className="navigation-item__link" to="/quote">
            Get a Quote
          </Link>
        </div>
      </div>
      )}
      {/*End domestic menu*/}

      {/*Start business menu*/}
      {isBusiness && (
      <div className="burger-menu__items">
        <div className="scrollarea">
          <MenuItem menuData={forYourBusiness} /> 
          <MenuItem  menuData={forYourHome} />
          <MenuItem menuData={forYourCommunity} /> 
          <MenuItem menuData={solarTogether} /> 
          <MenuItem menuData={company} />
          <MenuItem menuData={products} />
          <MenuItem menuData={infoCenter} />  
        </div>
        <div className="navigation-item navigation-item--shout">
          <Link className="navigation-item__link" to="/quote-commercial">
            Get a Quote
          </Link>
        </div>
      </div>
      )}
      {/*End business menu*/}

      {/*Start Solar Together menu*/}
      {isSolarTogether && (
      <div className="burger-menu__items">  
        <div className="scrollarea">
          <MenuItem menuData={forYourCommunity} /> 
          <MenuItem menuData={solarTogether} /> 
          <MenuItem menuData={forYourBusiness} /> 
          <MenuItem menuData={forYourHome} />
          <MenuItem menuData={forYourCommunity} /> 
          <MenuItem menuData={solarTogether} /> 
          <MenuItem menuData={company} />
          <MenuItem menuData={products} />
          <MenuItem menuData={infoCenter} />  
        </div>       
        <div className="navigation-item navigation-item--shout">
          <Link className="navigation-item__link" to="/contact-us">Contact us</Link>
        </div>
      </div>
     )}
      {/*End Solar Together menu*/}
      
    </div>
  )
}

export default BurgerMenu
