import { Link } from 'gatsby';
import React, { useCallback, useMemo, useState } from 'react';
import { ChildMenuData, MenuData } from './config';

interface ParentMenuItemProps {
    title: string;
    defaultOpen: boolean;
    children: ChildMenuData[];
    isInner?: boolean;
}

const ParentMenuItem = ({
    title,
    defaultOpen,
    children,
    isInner,
}: ParentMenuItemProps) => {

    ////console.log(title, 'defaultOpen', defaultOpen)

    const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

    const isOpenClassName = useMemo(() => isOpen ? 'open' : '', [isOpen]);

    const onClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    return (
        <div className="navigation-item navigation-item--has-children">
            <div className={`navigation-item__link ${isInner ? 'inner' : ''} ${isOpenClassName}`} data-title={title} onClick={onClick}>
                {title}
                <span className={`icon icon-fat-arrow navigation-item__arrow ${isOpenClassName}`}></span>
            </div>
            <div className={`navigation-item-children-container ${isOpenClassName}`}>
                {
                    children.map((child) => {
                        if (child.children) {
                            return (
                                <ParentMenuItem 
                                    title={child.title}
                                    children={child.children}
                                    defaultOpen={false}
                                    isInner
                                />
                            );
                        }
                        return <ChildMenuItem to={child.to} title={child.title} />
                    })
                }
            </div>
        </div>
    )
}

const ChildMenuItem = ({
    title,
    to,
}: ChildMenuData) => {
    return (
        <div className="navigation-item__children">
            <Link className="navigation-item__child-link" to={to}>
                {title}
            </Link>
        </div>
    );
}

interface Props {
    defaultOpen?: boolean;
    menuData: MenuData;
}

export const MenuItem = ({
    defaultOpen = false,
    menuData,
}: Props) => {
    return <ParentMenuItem 
        title={menuData.title}
        children={menuData.children}
        defaultOpen={defaultOpen}
    />
}