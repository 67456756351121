module.exports.reviewsCont = [
    {
    rating:`★★★★★`,
    reviewTitle:`Quick and Neat Installation`,
    reviewBody:`Signed up via Essex CC Solar Together. Paid £150 deposit
    and was surveyed 24 June but was told there may be a delay due to component shortages.
    But, was given a date of 22 July, I requested a short delay.
    Scaffolding went up 26th July, panels and batteries very professionally installed the next day, very
    neat wiring, up and running by the evening.
    Am surprised at the negative reviews.
    Only complaint so far is that the charge for bird proofing seems excessive and unjustifiable.`,
    userName:`Glyn`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Great technician`,
    reviewBody:`Our technician has been fantastic! Above and beyond! He
    has just left after fixing our system which developed a fault. He has always replied to queries
    straightaway and is friendly and knowledgeable.`,
    userName:`CAROLINE TAYLOR`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Love monitoring my system via the app`,
    reviewBody:`Love monitoring my system via the app, really useful to
    see what were using and when`,
    userName:`Joshua Adkins`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Decided to join the solar together…`,
    reviewBody:`Decided to join the solar together scheme and this was
    the best decision I have made. No hidden costs and a great system has been installed.`,
    userName:`Bryant Floyd`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Great experience and really happy we…`,
    reviewBody:`Great experience and really happy we went a head with
    it. Just waiting on the final documents now.`,
    userName:`Merle Byrd`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`The team went out of their way to…`,
    reviewBody:`The team went out of their way to ensure our needs and
    wants were met, could not have asked for me. Great service.`,
    userName:`Sylvester Lloyd`
    },
    {
    rating:`★★★★`,
    reviewTitle:`We don't consume much energy in…`,
    reviewBody:`We don't consume much energy in general, we went with
    solar for financial and property gains. It's been going well, our energy bill is nice and low. I'm
    overall really happy.`,
    userName:`Paula Colon`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Went with solar as it was an easy any…`,
    reviewBody:`Went with solar as it was an easy any effective way to
    reduce my CO2. We're really trying to do our bit for Net Zero 2030. Next is an electric car!`,
    userName:`Max Wheeler`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`The app is great`,
    reviewBody:`The app is great, especially on these sunny days. I have
    had a couple of questions which were easily answered via social media. Keep it up!`,
    userName:`Kylie Quinn`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`5 stars for Stuart!`,
    reviewBody:`Having replaced my internet router recently the Inverter
    fitted by GET became disconnected and the generation data was being lost. Stuart became aware of
    this situation and then contacted me directly - he then completed a site visit during which he
    guided me through the reconnection process. Having done this once I now feel able to do this again
    if needed. A big 5 stars to Stuart for his customer support.`,
    userName:`Robert Deakin`
    },
    {
    rating:`★★★★`,
    reviewTitle:`There had been some supply issues`,
    reviewBody:`There had been some supply issues, but the team were
    fantastic. They had a substitute brand which was of a higher spec and there was no additional costs.
    Installation went well just waiting on the documents but I've spoken to the team about that.`,
    userName:`Diana Jones`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Very helpful and professional support`,
    reviewBody:`When I eventually managed to contact someone in Customer
    Services, I eventually received a very positive and helpful call from Stuart from technical support.
    Stuart quickly identified the initial connection problem and expertly talked me through the remote
    reconnection process. Stuart's instructions were clear and professional resolving the first issue
    that I had. I later text Stuart after testing my heating boiler, acknowledging that he had limited
    expertise in the Immersun units. After localising the fault to the unit, Stuart arrived at my house
    to confirm our suspicions. Agreeing with my diagnosis, Stuart contacted Immersun technical support
    and after a long test process concluded that the Immersun unit was indeed faulty. Stuart was very
    professional and helpful throughout and I look forward to the final unit replacement or alternative
    permanent fix. Thank you Stuart.`,
    userName:`Brian Sherrell`
    },
    {
    rating:`★★★`,
    reviewTitle:`Thanks to Stuart`,
    reviewBody:`Following installation of our system, all seemed well.
    Until we noticed our battery was filling up with energy from the grid...so our system was costing us
    more, not saving us money!
    We are grateful to STUART who persevered to solve this problem. He was courteous, always replied to
    our calls, arriving at appointments when promised, and eventually reset our system....which has been
    OK so far, working as intended..... Hurray!
    THANK YOU Stuart.
    Don't know what we would have done without you.`,
    userName:`June Harris`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Call out from their technical guy…`,
    reviewBody:`Call out from their technical guy Stuart to solve a
    problem with our 4.0 kw PV system. Arrived on time and solved the issue quickly. Very
    courteous.`,
    userName:`Phil Brooks`
    },
    {
    rating:`★★★`,
    reviewTitle:`Engineers were fantastic`,
    reviewBody:`Engineers were fantastic, really wish the phone lines
    were answered a bit quicker and emails. Overall though I got everything answered.`,
    userName:`Matt Hardacre`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`A Very Happy Customer`,
    reviewBody:`I have had the solar panels and battery system from
    Green Energy Together for over a year now and I can say without any reservation the whole experience
    from planning, fitting and after sale has been excellent.
    Everything was well explained and the installation was professional and quick.
    I have no hesitation in recommending GET as a first class company.`,
    userName:`Jon Cox`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Happy with the system`,
    reviewBody:`Happy with the system. It's working well, got sent a
    video which explains the app which has helped me as I'm not the most tech savvy individual.`,
    userName:`Wyatt Palmer`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`I joined the solar together scheme`,
    reviewBody:`I joined the solar together scheme, super happy I did as
    the system produces enough to charge my batteries on those sunny days, which is great during the
    night as we stay off the grid for longer. Our energy consumption as already decrease. An investment,
    but great decision.`,
    userName:`Sam Dunning`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Added a retrofit battery to my present…`,
    reviewBody:`Added a retrofit battery to my present framework. The
    outreach group were fast and effective in helping me, client administrations were speedy with a
    date, and presently I can profit from putting away energy.`,
    userName:`Mikey Hood`
    },
    {
    rating:`★★★`,
    reviewTitle:`Dear Green Energy together`,
    reviewBody:`Dear Green Energy together, you did an excellent job
    installing my panels last July and I have been generating lots of electricity to feed into the grid,
    but where is it all going, should you be informing my supplier so I get paid. Love to hear from
    you`,
    userName:`Hugh Humphreys`
    },
    {
    rating:`★★★★`,
    reviewTitle:`While having had several problems with…`,
    reviewBody:`While having had several problems with the installation
    of solar panels, we were very happy with the way their engineer, Stuart, managed to fix the problem
    of the FoxCloud app not showing any data for several weeks. We can now charge the electric car
    knowing we are not using as much costly juice!`,
    userName:`Roger Tarrant`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`GET have stepped up`,
    reviewBody:`I recently had a fault on my system, the GET team were
    so quick and helpful. An engineer came around the following day, let me know that my inverter needed
    resetting. Once he was done, he checked my app monitoring, I had a couple of questions he called
    customer services and I had answers. Really went that extra mile.`,
    userName:`Merlin Golf`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Thanks for EV charger installed`,
    reviewBody:`Thanks for EV charger installed! Good service`,
    userName:`rose zakharov`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Now in conversation with Stuart`,
    reviewBody:`Now in conversation with Stuart, an electrician, who is
    assisting with an inverter problem.`,
    userName:`Mike`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Generally speaking the service was…`,
    reviewBody:`Generally speaking the service was good. I believe the
    price was excellent. The installation was good and the the installers were polite and efficient. The
    only problem I had was that it was impossible to get hold of Green Energy Together when I had a
    problem.`,
    userName:`Julian Sanders`
    },
    {
    rating:`★★★★`,
    reviewTitle:`When the installation team eventually…`,
    reviewBody:`When the installation team eventually arrived everything
    went very well. Getting to the point of installation was not entirely smooth, we had a couple of
    missed installation dates where we were not advised the installation could not attend which was a
    bit frustrating. In summary installation is very good administration upto that point could do with
    some improvement.`,
    userName:`Jason Thomson`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`great value system nice company to do…`,
    reviewBody:`great value system nice company to do business
    with.`,
    userName:`dbz`
    },
    {
    rating:`★★★★`,
    reviewTitle:`I was disappointed that it was done in…`,
    reviewBody:`I was disappointed that it was done in 3 goes.
    1. The panels where fixed, but that engineer did not have the battery.
    2. After a long wait and reminders the battery was fixed.
    3. I paid and thought it was all done, when yet another engineer came to finally finish the job.
    ALSO, IT TOOK A VERY LONG TIME TILL THE SCAFFOLDING WAS REMOVED, which made my bedroom very dark. I
    think, over 1 month, again with several emails to remind them.`,
    userName:`Evelyn Shamash`
    },
    {
    rating:`★★★★`,
    reviewTitle:`The final installation was efficiently…`,
    reviewBody:`The final installation was efficiently and
    professionally completed. However there was a point when the promised two installation dates were
    not met that we questioned if we had made the correct decision. Apparently the delays were caused by
    erecting scaffolding at our property. When finally a new scaffold company were employed the fitter
    told us there wasn’t a problem with the house but the survey had shown a completely different
    scenario. Response to our enquires was met in good time and helpful.
    The ultimate result seems to have fulfilled our wishes, albeit we lost several operational months in
    2021.`,
    userName:`Rita`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Green Energy Together customer after sales service is the best`,
    reviewBody:`I had an 8 solar panel plus 3 battery installation on my
    bungalow and there was a couple of issues with the batteries after installation. GET pulled out all
    the stops and didn't hesitate to change whatever was required to be changed to make the installation
    work as it should have. I can not fault them on their after sales service and would recommend them
    to anybody who would listen. Peter`,
    userName:`Peter J Sell`
    },
    {
    rating:`★★★`,
    reviewTitle:`Polite and friendly staff but let down by poor communication between staff and with customers`,
    reviewBody:`I realise the fitting of my solar panels was done in
    difficult times due to Covid19 but many of the company's failings were due to communications
    problems within the organisation and with contractors and failures to think through the process from
    the customer's point of view. Thus a job that should have been completed in in just a few days
    dragged on for over a month and work was rarely carried out without the exchange of a large number
    of emails and, when these were not replied to promptly, follow-up phone calls. The the date for
    installing the scaffolding was cancelled at the last moment only for them to turn up on the
    scheduled date. The installation date was then put back for 2 weeks regardless until I complained
    after which it was installed during the same week as the original installation date. Although the
    work appears satisfactory (6 weeks after installation) and the company's staff were polite and
    competent, the job took much longer than it need have due to the bird deterrent not being available
    when needed so having to be installed separately two weeks later, the installation being spread over
    two days and then the scaffolding (which obstructed our satellite signal) not being removed without
    special pleading. But the problems didn't end with installation. Getting hold of the app to monitor
    the power generated took some time getting and it does not also monitor the level of charge of the
    battery. Also when I attempted to set up a SEG with my power supplier I discovered the "Handover
    Pack" sent to me following payment (which was demanded before the job was completed) did not include
    one of the certificates (the G98) that the company demands in order to set it up nor a "paid in
    full" invoice - so setting this up was also delayed unnecessarily. On the plus side, I did get to
    know the administrative team at Green Energy Together quite well and was supported by one of their
    customer champions to sort out the many hitches I encountered. I would have thought however that
    many of the problems could have been avoided with better preparation and planning and by looking at
    things from a customer point of view. If appointments are all subject to change at the last moment
    due to the weather or lack of materials or staff then this should be made clear from the start so
    expectations are not raised and customers can bear this in mind for their own commitments. I suspect
    I may have been one of the first in my area to have their solar panels installed so am hoping than
    some of these issues will be resolved when the staff involved become more experienced in overseeing
    and co-ordinating the process.`,
    userName:`Robert from Enfield`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Very Happy`,
    reviewBody:`The process was quick and easy. The team were very
    friendly and quick to assist me with any questions I had. I am monitoring it from the app every
    day!`,
    userName:`Geraint Murray`
    },
    {
    rating:`★★★★`,
    reviewTitle:`slow start but well installed`,
    reviewBody:`It took a long time for the installers to understand how
    to carry out the job. After to-ing and fro-ing, including wasted trips on the part of the first
    installers and also a surveyor, installer Kev came all the way from somewhere in the west, well out
    of London.
    
    The actual installation, when finally it got going, was excellent. Kev, the installer, was very
    co-operative and resourceful. He took note of what I preferred in terms of the cabling and other
    components, finishing the work within a day despite it being a bit more complicated and being alone.
    
    At the time of writing this I still have not been able to make the app work properly on my
    smartphone. Kev did install it properly and showed it once but returning to the informative pages
    that Kev showed me.
    
    After installation service has been excellent. Ms.Terry has kept in touch and seems eager to help me
    out. Beyond this, only time will tell.`,
    userName:`Surendra Nayar`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Very helpful people`,
    reviewBody:`Very helpful people: understood the problem and sorted
    it out with minimum effort.`,
    userName:`R C Lister`
    },
    {
    rating:`★★★★`,
    reviewTitle:`We accepted Devon County Councils offer…`,
    reviewBody:`We accepted Devon County Councils offer of a reduction
    in the cost of instillation of the panels as they were able to agree a price with Green Energy
    Together for bulk purchase. The instillation was carried out efficiently and with no major
    disruption. The only minor problem was that the instillation of the device to heat the immersion
    heater was delayed and took a bit of chasing to get an instillation date, but other than that the
    only other problem was getting the software to work. The solution suggested was to get a booster for
    the internet router, which we did but at an extra cost. This appears to have solved the initial
    software problem and now everything works fine. We have definitely seen a reduction in our
    electricity usage taken from the Grid when it is sunny. When the batteries are full the surplus goes
    back to the grid. It is unfortunate that we do not get paid for this since the tariffs were taken
    away. Overall very pleased with the outcome. I doubt we will ever get to a point that the energy we
    save by having the panels installed will cover the costs of instillation but at least we are
    benefiting from knowing that we are using a natural resource, the Sun, when it
    shines.😆😆👍👍`,
    userName:`Mr Paul Heydon`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`You were and are easy to contact (by…`,
    reviewBody:`You were and are easy to contact (by phone)
    Your employees are understanding and able to react and solve problems quickly.
    Engineers were professional courteous an efficient. All done in two days. Final papers and invoices
    took too long to reach us and scafolding scaffolding remained up for over a week.`,
    userName:`Mike Hage`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Excellent recovery from almost going pear-shaped!`,
    reviewBody:`In the beginning, Green Energy Together represented all
    that was bad in customer service.
    They never did what they said would do - it took five appointments before my storage battery was
    eventually fitted; the parts were delivered without warning and incomplete - the list goes on.
    But credit where credit's due, they turned the corner with the appointment of Clive Houlston as
    Customer Service Director and Leona Marcus as Customer Services Team Leader. Things are definitely
    much better now and I wouldn't hesitate to recommend them to you now.
    I must also mention engineer Andy Moss who installed the storage battery. He did a great job.
    Sometimes making a spectacular recovery from a disaster is (almost) as impressive as getting it
    right first time. Well done Clive, Leona and Andy at GET.`,
    userName:`SteveGerry`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Affordable solar and great customer experience`,
    reviewBody:`Affordable solar is already generating savings-
    financial savings and energy savings. I recently had a minor technical problem - a big thank you to
    Gemma who helped arrange and engineer call out and Stuart the engineer who visited and resolved the
    issue within an hour. Great to share a great customer experience. Delighted with my solar set
    up`,
    userName:`Dr Page`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Green Energy Together installed…`,
    reviewBody:`Green Energy Together installed batteries to store the
    electricity generated by our existing solar panels. The firm was very efficient and the work was
    carried out very neatly and expertly. The system and the software work extremely well. Many
    thanks!`,
    userName:`Janet and Glenn`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Get GET!`,
    reviewBody:`On the whole GET have done a pretty good job. At times,
    its customer service can be difficult to get focused and joined up, but they GET (geddit?) there in
    the end. We’ve had some first class hands on service from Stewart and Gemma has been great on the
    phone in ensuring we get a good after sales service. The system seems to be working very well,
    although it’s a bit early to say for certain the economic benefits of the installation, although the
    early signs are good. As with all organisations, I’m confident GET appreciates there is always room
    for improvement.`,
    userName:`Porkiespadge Jr`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Excellent experience`,
    reviewBody:`Excellent experience`,
    userName:`Janet Redford`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Great service and value.`,
    reviewBody:`Great service and value.`,
    userName:`KEV THORNE`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Overall the company " did what it said…`,
    reviewBody:`Overall the company " did what it said on the tin". The
    whole process took longer than I had expected but the staff were always pleasant and helpful.There
    could have been more information about selling the surplus power, but that information is available
    online.There is something very pleasing about watching the graphics which show how much power you
    are producing!`,
    userName:`Pat Smith`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`From my point of view...`,
    reviewBody:`SPUK 54524: From my point of view, investing in new
    technology such as Solar Panels and Batteries was a daunting prospect, however, at the beginning
    Green Energy Together went to great lengths to explain how such a system could be installed, and
    then used to reduce bills and help - in a small way - to save the planet. Following on from a
    survey, the equipment was delivered and installed by a small team of competent, well mannered men
    who went out of their way to complete the work to a very high standard. I now feel very pleased with
    my decision to get this work done, as well as having the added bonus of checking the stats using my
    mobile phone.`,
    userName:`Robert Deakin`
    },
    {
    rating:`★★★★`,
    reviewTitle:`The installation was expertly done and…`,
    reviewBody:`The installation was expertly done and went to plan but
    all workmen arrived with no face masks`,
    userName:`Martin Lette`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Very happy with the service`,
    reviewBody:`Very happy with the service received on the installation
    of our new solar powered storage batteries. Engineer was very helpful and even though he had to
    amend the planned location he did the installation swiftly and efficiently. Would definitely
    recommend.`,
    userName:`brian williams`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`
    
    ^^^^
    class="css-1hjlfi9">Recommendedan></a>`,
    reviewBody:`Work carried out to a good standard and in good time.
    Everyone helpful throughout the process.`,
    userName:`Mr_Ron`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Very satisfying`,
    reviewBody:`Great to have our panels up and running and providing
    energy. Crew were very quick and efficient at installation. A few niggles along the way but all
    sorted now and it's great to have everything explained by the experts. Really satisfying to see so
    much of our energy coming from solar now.`,
    userName:`Julie`
    },
    {
    rating:`★★★★`,
    reviewTitle:`bad organisation of installation, but excellent service including after sales`,
    reviewBody:`GET installed batteries for storage of spare electricity
    from our PV.
    
    The installation appointment process was very badly managed. On the appointed day, an installer
    showed up, but batteries were not delivered. He had to go back. On the second attempt, we had a call
    the day before to check if the batteries were there, but they weren't. However, we were reassured
    they would be. When the second installer arrived, still no equipment. However, he called, proceeded
    with preparation and everything was delivered later.
    
    Installation itself was very neat and professional. The equipment works well.
    
    After sales service was incredibly good. We had a smart meter installer that messed up the
    monitoring device (clamp on the supply cable). GET reacted very quickly, sent someone over who fixed
    it and checked if everything was working fine. All for free!`,
    userName:`Kris`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Positive experience good value and very professional`,
    reviewBody:`Purchased through Enfield Council group buying scheme,
    At the same time got a couple of quotes from other suppliers which did not compare favourably
    particularly with regard to the battery. The installers broke a number of tiles on the roof but told
    me about the damage and took photographs and all the tiles replaced and made good
    I would recommend the company wholeheartedly`,
    userName:`Steve P`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`System works well`,
    reviewBody:`System works as sold and they were very quick to answer
    my questions. No complaints.`,
    userName:`Max Thorne`
    },
    {
    rating:`★★★★`,
    reviewTitle:`All’s well that ends well.`,
    reviewBody:`Despite some initial problems at the early stages
    regarding potentially being charged twice for scaffolding hire, we were ultimately very happy with
    the supply and installation. I would recommend this company, but do lots of reading first about the
    amount of equipment you’ll need for the system you choose. (We were shocked to find that we don’t
    get 100% use out of the batteries - for safety reasons 20% battery use has to be retained and you’ll
    be using from the grid again at that point).`,
    userName:`Mrs Wendy Wilshaw`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`New Batteries and Car Charger working…`,
    reviewBody:`New Batteries and Car Charger working great. I have
    solar PV already and with the unsteady energy prices adding batteries to charge from the solar is
    saving me even more so far. Installers were profession enough and I had a call to explain the system
    the next day which was helpful.`,
    userName:`James Ewers`
    },
    {
    rating:`★★★`,
    reviewTitle:`Mixed experience, but improving`,
    reviewBody:`My experiences with GET have been mixed, so let me
    explain.
    
    As part of the Solar Together buying scheme, they supplied me with battery storage and an Electric
    Vehicle charger.
    
    Physical installation of the battery storage was fast and neat, although the hardware failed
    immediately (this happens, I know), and this is where the picture is not so positive. It took a long
    time to get the Support Team to engage with this problem (many calls and emails). Ultimately it took
    determined escalation to GET management to get the equipment replaced. Since replacement, everything
    works well.
    
    Please understand, in my interactions everyone was polite and helpful (e.g. they deferred payment
    until the issue was resolved), but action was slow and it took too much effort on my part. More
    recently, my interactions with the Support Team over twitter have been first rate - quick and
    effective. Perhaps they are overcoming some internal overloads or staffing issues.
    
    The EV charger installation in contract was fine, after an initial delay for parts (not their fault
    given world logistics). The device they have chosen is neat and works well.`,
    userName:`Trevor Davis`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`A great outcome`,
    reviewBody:`Whilst the start date of the installation was changed
    for very understandable reasons relating to COVID, once things were under way everything went really
    well. The installation team worked really hard and went out of their way to create a very
    professional outcome. Afterwards, it did take a little while to sort out the internet access but
    once that was completed I was able to see all the information that I needed relating to how the
    equipment is performing.`,
    userName:`Robert Deakin`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Trouble free installation`,
    reviewBody:`Once an installation date had been agreed, everything
    went smoothly. The delivery was on time. The installers were very pleasant and efficient. Small
    problems were dealt with effectively. There was no mess. Office staff were also friendly and
    helpful.`,
    userName:`C F Wood`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`My experience has been very good`,
    reviewBody:`My experience has been very good. Customer Services were
    responsive to emails and installation team were excellent - very tidy and neat and explained
    everything well.`,
    userName:`Anon`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Solar Battery installation`,
    reviewBody:`I already have solar panels so this review is for the
    solar battery installation only.
    
    The whole process from start to finish was handled with great care. Due to the pandemic, a site
    visit was not possible which is totally understandable in the circumstances but all my questions
    were answered prior to me putting down a deposit.
    
    The day of installation went really well and the installers listened to my ideas and worked with me
    to get it installed in the way that I wanted it done.
    
    The only issue I had was with the wireless data logger which stopped working after a few days, I
    requested a wired dongle instead and this was then fitted by one of their installers. Due to my
    fault, the ethernet cable was not long enough to fit but we overcame that issue and it now works as
    it should.`,
    userName:`Binesh M`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Great customer service`,
    reviewBody:`The process was quite smooth, I definitely appreciated
    the customer service response, although I had to call to get the survey and installation dates
    booked.
    Scaffolding installation was not done on the agreed date, but fortunately it was completed on time
    for the installation of the panels.
    The installation team was competent, but probably one person only for the main installation process
    was not a good choice as hard work had to be carried out.`,
    userName:`Antonello Miscimarra`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`SPUK43213 Happy Customer`,
    reviewBody:`SPUK43213
    The installation was completed in just over a day(20 panels 2batteries) by two hard working polite
    engineers who on completion were at pains to explain how the system worked and that we were happy
    with everything. We would recommend them to anyone.`,
    userName:`Biggles`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`SPUK 86247`,
    reviewBody:`SPUK 86247
    
    Good service with on time installed of installation of solar panels within Two days.
    
    Recommended by Harringay council and London Moyer office.`,
    userName:`Pravin Khatri`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Green Energy Together review`,
    reviewBody:`Excellent customer service. The roofer is very good and
    replaced damaged tiles on the roof. The electrician is excellent. Good customer service and good
    advice. However, the scaffolding firm were a disaster. They gave me a date and I had to wait a day
    in. They did not turn up and no phone call. When they were to remove the scaffolding I had to wait a
    day and again they did not turn up or ring to say that they were not coming. That's why it's 4 star
    rather than 5 stars.`,
    userName:`kostakis`
    },
    {
    rating:`★★★`,
    reviewTitle:`Installation of solar battery storage`,
    reviewBody:`Installation - installer and assistant were both polite,
    efficient, friendly, explained everything I needed to know, and answered my queries fully. They
    arrived punctually, asked me relevant questions and installed much more quickly than anticipated. I
    want to lay a new floor underneath the storage batteries at some point: the process for moving them
    in order to do this was clearly explained to me.
    
    Communication: Staff polite and helpful when I talk to them or get emails, but direct communication
    is otherwise very difficult. I was phoned numerous times, but no text message was ever sent to tell
    me who I needed to contact or what call was about. When I called back via switchboard, I never got
    through to anyone, even being 'put through' once to a dead line. Email was much better, but took a
    while to get a response. When I did get a call I could answer, everything was very efficient and
    follow-up via email was fine. Not sure that staff teams always communicate changes to each other,
    though; after a phone call conversation, I had installation moved to two days earlier than the
    original appointment, but had subsequent conversation where staff didn't seem to know this. I also
    had to re-send (different and same) photos of installation area several times, since requirements
    for information kept changing.
    
    Payment: easy, but had to prompt for email receipt of payment.
    
    Still waiting for 'Welcome Pack' I was promised via email. This should arrive soon, though.`,
    userName:`Mrs Gray`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Solar installation`,
    reviewBody:`The guy's were great and did a good job. The system
    works as it should.`,
    userName:`Carl North`
    },
    {
    rating:`★★★`,
    reviewTitle:`Pleased with the results`,
    reviewBody:`Pleased with the results. Good scheme in general, any
    faults in the experience likely down to covid complications and subsequent backlog which is
    unfortunate for all. Communication could have been far better when it came to installation and date
    changes following installers being pinged etc. General comms on this to all would have helped. App
    is great, but little adjustments like being able to change the feed in rate (once agreed) and the
    currency to GBP, exporting data etc. would be good.`,
    userName:`Nick`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Very pleased with the installed system…`,
    reviewBody:`Very pleased with the installed system of 16 panels and
    two batteries and have requested an upgrade with 10 more panels and two additional batteries. The
    additional batteries should keep me from buying electricity during the night and help with the peaks
    when I top up the electric car battery ( Its a Zoe which is a great car). Response seems to be
    improving and using Messenger has helped with dependable same day response. It was explained that
    poor telephone response was due to Covid, so lets hope this is behind us. Staff are great and
    helpful once you make contact. Lets see how the installation goes and hopefully a 5 star
    review.`,
    userName:`Hugh Humphreys`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Was very happy with the sales process`,
    reviewBody:`Was very happy with the sales process - very transparent
    and professional. The installation very smooth. I'm delighted all round and would recommend this
    company.`,
    userName:`JS`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Great service from start to finish`,
    reviewBody:`Great service from start to finish. Very friendly and
    helpful installers, who rerouted wiring at my request. Thoroughly satisfied with the whole
    experience. Thanks Green Energy Together!`,
    userName:`Tommy Harkess`
    },
    {
    rating:`★★★`,
    reviewTitle:`Some things about this scheme were very…`,
    reviewBody:`Some things about this scheme were very good and some
    were very poor. The good things were at the beginning and at the end. The bad bits were in the
    middle.
    Initial explanation of the scheme was excellent and everything up to an including the survey went
    really well. Then things started to go wrong. The surveyor raised some technical issues on which we
    needed clarity before we were able to proceed. it proved impossible to get any kind of response from
    GET by e-mail and the phone was never answered. Weeks went by and we lost our installation slot of
    29 March.
    We finally received a helpful phone call from someone who explained the issues to our satisfaction
    and promised to send a revised contract which
    then didn't arrive for ages.
    When we did receive it we signed it and sent it back the same day. Then there were more weeks of
    silence - no acknowledgment of receipt or suggestion that we were scheduled for an installation. I
    finally managed to get through on the phone and was given an installation date there and then of 9
    August - 4 1/2 months late.
    We were then rung up on the Friday before to tell us the panels were being delivered on the Saturday
    so we waited in all day and on the Sunday but nothing turned up and no one contacted us to tell us
    there was a change of plan.
    When the installation finally took place on the Monday the installers were superb, especially Jake,
    the roofer, who was astonishingly quick and efficient and carried on working through torrential
    rain. Rob, the electrician was also extremely helpful and talked me through the system. It is all
    working now and we are very pleased with it.
    
    All just a shame that you were let down by the back office administration. Overwhelmed by the volume
    of the response perhaps?`,
    userName:`NIGEL BANKS`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`The whole solar installation process…`,
    reviewBody:`The whole solar installation process from the online Q
    and A’s, scaffold build and installation of the panels has been excellent. The staff were
    knowledgeable, professional and friendly.`,
    userName:`Kate Smith `
    },
    {
    rating:`★★★★`,
    reviewTitle:`Kept informed when there was an delay…`,
    reviewBody:`Kept informed when there was an delay in the
    installation date due to positive case of covid in the team. Turned up on the revised installation
    date. Courteous team. High standard of installation and system operation was explained on
    completion.`,
    userName:`James Godiff`
    },
    {
    rating:`★★★★`,
    reviewTitle:`A happy customer`,
    reviewBody:`The panels were installed in January by fitters who were
    quick, friendly and very helpful. They took me through the workings of the app which is used to
    monitor performance and which was very useful advice. Since then, even on cloudy wet days, I export
    to the grid and the last bill I've seen showed a net electricity bill of £1.30 for June/July, a
    period of poor weather here.
    I'm delighted with the installation and only wish I'd done it years ago.
    Why not 5 stars then? After 2 months I realised that the paperwork related to the export process was
    incomplete hence I lost the financial benefit initially. It's a pity there's not a checklist to
    ensure all paperwork has been processed. That apart, I'm happy and of course the finances are only
    part of the justification: there's the green aspect to it too.`,
    userName:`Malcolm Hood`
    },
    {
    rating:`★★★★`,
    reviewTitle:`My Green Energy Together experience`,
    reviewBody:`I was pleased to take up the opportunity to renew my
    Solar batteries but regretted that the Inverter already operating satisfactorily had to be replaced
    as well.
    The installers were very efficient and helpful and the return visit a few days later very
    reassuring.
    The App to monitor the operation of the batteries doesn't allow for a forgetful person, or one who
    mistypes keys, to enter a password and check it whilst setting up the App. This means I am currently
    unable to monitor the operation of my new batteries and will, apparently not be able to do so for a
    few weeks.`,
    userName:`Roy`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Solar Battery Installation`,
    reviewBody:`We had a solar battery installed. The installer was
    polite and helpful, and explained how everything works. My only complaint is that we did not know
    what time of day the installer would arrive, until 45 minutes before he arrived.`,
    userName:`Catherine`
    },
    {
    rating:`★★★★`,
    reviewTitle:`All I needed was a battery`,
    reviewBody:`All I needed was a battery (I already had pv panels) - I
    attended an online webinar - read around the subject and placed a few calls with the help desk. I
    was recommended to go for a 5kw battery - but I knew what I wanted and I went for 13.6kw! I was
    quoted a very reasonable price - cheaper than I had been quoted elsewhere. The installation was
    difficult to get going as the first engineer who helped struggled to understand the brief - however
    a different engineer was appointed and I was given support from a team manager who answered all of
    my questions. I learned a lot about the product and how to maximise it usage from the online contact
    that I had with the team. Very happy I had this installation - only wish now I had gone for larger!
    You might want to know why? But with the advent of electric vehicles and rising fuel prices my pv
    systems are really making so much green sense - I want to get as much value out of them ! And I am
    doing my bit for our planet :-) I would recommend the team, the product, and the aftersales team.
    Thank you for your help!`,
    userName:`Jon J Wood`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Oh now for a sunny summer!`,
    reviewBody:`Overall we have been pleased with the whole process of
    having solar panels and batteries installed.
    The installation date was changed once due to the panels not being available.
    The scaffolding firm and team were excellent and took into account our requests , arrived and
    departed as agreed.
    The installation team were unaware of a time request we had made to GET but their installation work
    was efficient and tidy. They explained what they were doing and made sure we understood all the
    ‘gadgets’.
    The phone and email contacts I have had with GET have been friendly but I feel that information
    doesn’t always get through to other departments .
    The concept of group purchase is a good one but the scale of uptake of this operation may have put a
    strain on the organisation level of this company.`,
    userName:`Mrs J Thomas`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Very good on-the-premises team`,
    reviewBody:`The installation team which attended our property was
    excellent and we are very happy having the panels installed and working.
    
    However, a few problems:
    1) Our installation date was changed twice; on one of the occasions I had to call GET because the
    scaffolding hadn't turned up. The reasons given were not unreasonable but more notice would have
    been better.
    2) The panels did not arrive on the day they were supposed to and did not arrive until late (after
    6pm) on the installation day, causing the completion of the work to be the next day.
    3) A small amount of waste material was left at our house, some of which could be re-used on another
    installation
    
    The installers coped well with the disruption by completing everything they could in advance of the
    panels arriving.`,
    userName:`Chris`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Overall we are very happy with the service we have received and with the results of the install`,
    reviewBody:`As I sit writing this review this morning we have spent
    2p on electric and our solar battery is charging (now at 43%), even with the washing machine
    running. Even on overcast days we are seeing the panels producing electricity. We have reduced our
    reliance on grid electricity by about 50% since install. We hope that in the summer months we will
    energy neutral.
    
    The process of applying for the solar panels was very easy, and we felt informed. The price for what
    we received was very good, and the team worked with me on comparisons to make the best of our budget
    vs how many panels we could fit on the roof.
    
    The installers (particularly Alan) worked really hard to install the system through adverse weather
    conditions (wind and rain), and worked with our awkward building to install the system without it
    being too intrusive.
    
    The reason for four stars as opposed to 5 is that we did have some teething problems with the set up
    which did take a little longer than I would have hoped for to get resolved. However, Clive (at Solar
    Together) and Alex (GET) were really helpful and resolved the problem. All correspondence from the
    customer service team have been friendly, professional and polite. Also, the app needs a little work
    to make it a bit more end user friendly (I'm enjoying seeing the data graphs on it though!)
    
    Overall we are very happy with the service we have received and with the results of the install. In
    fact, we may well get some more installed when we (hopefully) get an extension to our house.`,
    userName:`SandTAllen`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Solar Together installation`,
    reviewBody:`We are very pleased with our installation and the
    company kept us fully informed as the install progressed`,
    userName:`customer`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Professional in every aspect with full…`,
    reviewBody:`Professional in every aspect with full explanation
    before fitting and all questions answered, excellent fitters that knew the job inside out that has
    resulted not only in the panels looking aesthetically pleasing, but doing the job intended. I am
    delighted.`,
    userName:`Consumer`
    },
    {
    rating:`★★★`,
    reviewTitle:`Excellent initiative but appalling communication and misinformation`,
    reviewBody:`The initiative is an excellent idea and the initial
    process and information I got after signing up was very good. However, questions took over a month
    and a reminder to be answered and two of my questions regarding the space and location required for
    the solar batteries were answered incorrectly (both on the phone and by email) which led to a lot of
    confusion when the installation team arrived and we had to put them somewhere else. Also, the team
    arrived in the afternoon instead of the booked time in the morning without letting us know. So,
    overall happy with the scheme and company but the misinformation and communication were
    appalling.`,
    userName:`Household from Teignbridge`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Solar panels`,
    reviewBody:`Installation team were great. Unfortunately pre and post
    installation communication could be better.`,
    userName:`Christopher`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Great company`,
    reviewBody:`Great company, cheaper than buying the parts separately.
    System works brilliantly and their support has been fantastic.Definitely worth contacting them for a
    quote and to see if they have any offers running.`,
    userName:`Michael`
    },
    {
    rating:`★★★`,
    reviewTitle:`Office contacts is very poor nobody…`,
    reviewBody:`Office contacts is very poor nobody rings back making it
    very difficult to speak to anybody phone rarely answered.`,
    userName:`Martin`
    },
    {
    rating:`★★★`,
    reviewTitle:`Not a good start, but now sorted`,
    reviewBody:`Having responded to a Devon county councils promotion of
    installing solar panels using a VETTED company , well my experience with this company has not been
    good, all I would say is if you use this company have the roof checked or check it yourself as I’ve
    been left with smashed up tiles for which I am still waiting a week later to be put right,l covered
    them over with plastic sheet to keep the rain out,also there has been other problems, communication
    is poor, I would now like to add that problems have now been sorted thanks to Alex and Jordan from
    GET`,
    userName:`Roy Johansen`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Pleased this was done`,
    reviewBody:`The installers turned up on the day when agreed and
    effected the installation. When the engineer came to install the dongle, he did install an
    additional fuse box as the original installation could have tripped the whole house electrics if
    there had been a problem. However, the work was always safe and has worked fine.`,
    userName:`Mike Dennis`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Highly recommend this kit`,
    reviewBody:`Initial communication could be better, with dates given
    then altered, installation was undertaken on the third attempt, however once the team did arrive
    they did a fantastic job, very quick, safe and efficient. Top marks for this team, they thoroughly
    explained the total system, helped sort out the app on my phone and showed how to all worked. The
    installed kit is superb, absolutely no regrets in buying this kit, highly recommended`,
    userName:`Robert Clark Bryson`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Excellent team very professional from…`,
    reviewBody:`Excellent team very professional from start to finish.
    The team were on time completed the work fitting the battery pack and cleared up as well. Before
    they left they explained everything and set up the app for me. The contract was very easy and the
    service was first class.`,
    userName:`Andrew Judge`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Quick and easy solar panel installation`,
    reviewBody:`The solar panels were installed last week. The
    installers arrived when expected and were friendly and professional, completing the job promptly and
    explaining everything to me. They made no mess.
    Prior to that, the setting up of the contract was straightforward online and my only regret is that
    I didn't seize the opportunity to have this done years ago.`,
    userName:`Malcolm Hood`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Excellent service on all counts`,
    reviewBody:`Excellent service on all counts. Clear instructions and
    communication. Fitters were pleasant, polite and masked. All went smoothly and as expected.`,
    userName:`CAROLINE TAYLOR`
    },
    {
    rating:`★★★★`,
    reviewTitle:`My dealings with Solar Together have…`,
    reviewBody:`My dealings with Solar Together have been excellent
    and they should be encouraged and thanked for bringing about bulk purchase of solar panels and
    battery storage in Devon.
    We need more such schemes across the country to accelerate the installation of rooftop solar and
    storage.
    My experience of the contractor installing the battery is mixed. The equipment arrived unannounced
    the evening before installation, fortunately I was at home. The workers installing the battery were
    polite, did the job quickly and left the site tidy. However there was no on site survey before
    installation. Just photos from myself of the location. Had there been and the details of the
    invertor and battery made clear to me I would have insisted on additional mechanical protection
    for the battery and exposed wiring. I believe an inadequate risk assessment has been carried out
    of the risks arising from the hazards created by the battery.
    Hazards as follows:- Tripping; electrocution; Fire; explosion. Although risks arising from hazard
    are medium, I believe having located the battery in a heavily used garage, with clearly lots of
    items stored and in regular use it should have been clear to the installer that additional
    mechanical protection was required for the battery and protruding wiring, including mains leads.
    By providing suitable mechanical protection the risks could easily be reduced to an acceptable
    level.`,
    userName:`Peter Smith`
    },
    {
    rating:`★★★`,
    reviewTitle:`Could do better`,
    reviewBody:`Slow response time or no response and lack of clear
    communication at times.`,
    userName:`Ravi`
    },
    {
    rating:`★★★★`,
    reviewTitle:`In the end I was very pleased with the…`,
    reviewBody:`In the end I was very pleased with the installation and
    would thoroughly recommend them. The only reason I didn't give them 5 stars was because of some
    early issues with what I wanted (the surveyor who was a contractor to the company ignored my
    instructions and designed it the way he wanted hence the panels didn't fit where he wanted them to
    be and the fitters therefore turned up with the wrong parts) The customer should always be right
    especially when they have an engineering background and a degree in related technology. The
    instillation people were excellent and very understanding with all my needs. One spent about 5 or 6
    hours driving back to their depot to get the correct parts in rush hour traffic hence a 1 day job
    turned into a 2 day job. Minor issue with the handover pack which was soon sorted and I wouldn't
    mark them down for that.
    
    To reiterate The instillation was in the end installed professionally and to a high standard. Its
    working very well with my property being near energy self sufficient in the summer months. I would
    thoroughly recommend this company.`,
    userName:`Peter J Sell`
    },
    {
    rating:`★★★★`,
    reviewTitle:`Fast turn round for the survey and…`,
    reviewBody:`Fast turn round for the survey and installation but bit
    of a wait for the formal documentation pack. Equipment expertly installed and works well, very
    pleased.`,
    userName:`Peter`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Excellent service`,
    reviewBody:`Excellent service, excellent product, professional,
    friendly staff.`,
    userName:`Susan`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Really pleased`,
    reviewBody:`Very Happy with the Solar Panels and Battery Storage
    they provided. Im based in the Southwest and no one else came close to the price they offered.
    Technical Handover was good and I can see the batteries working which is fantastic. Highly recommend
    to anyone interested in renewable energy at an attainable price`,
    userName:`Eric Nichols`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`A very smooth process.`,
    reviewBody:`A very smooth process from first contact. Good, clear
    information throughout. Excellent team of fitters; very efficient and polite when dealing with
    us.`,
    userName:`Michael`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Very happy with the process`,
    reviewBody:`Just had a 5k system installed and it was the most
    seamless experience. They were quick to reply to my request, I got way more information from them
    than from any other supplier and everything went great with set up and installation. Very happy with
    the process.`,
    userName:`Florina Farcau`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Great company, Competitive and Great Service`,
    reviewBody:`Great company. The quote was very competitive and the
    survey process quick and easy. On the day of install the 3 man team were on time, professional and
    delivered a nice system. I have a 3kw solar and 5kw battery system delivering me clean energy for
    years to come. Happy customer!`,
    userName:`Darren Francey`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Really happy`,
    reviewBody:`Really happy.
    Within 2 weeks of quoting they had been out to survey and installed my solar panels. The office
    called me the next morning to check I understood how it all worked. I had four companies quote and
    they were by far the best price also.`,
    userName:`Ceri Jones`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Very happy with the system we purchased…`,
    reviewBody:`Very happy with the system we purchased from Green
    Energy Together. Their work was beyond my expectation. Michael was very efficient to deal with and
    the communication was excellent.`,
    userName:`David Gilder`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Good Service`,
    reviewBody:`I'm really happy with my solar installation, everything
    was installed within the day and looked exactly how they explained on the survey. They even gave me
    some advice regarding a water heater controller I had fitted after the panels were installed. I
    would recommend them to anyone wanting to save money as it really made a difference to our
    bills.`,
    userName:`Kate Jones`
    },
    {
    rating:`★★★★★`,
    reviewTitle:`Going green`,
    reviewBody:`GET installed solar, storage and ASHP in my home,
    reduced my energy bills by 50% and allowed me to go green for less than I thought it would cost me,
    Ryan and Michael done a great job`,
    userName:`Callum Bailey `
    },
    {
    rating:`★★★★★`,
    reviewTitle:`I am a 75 year old widow`,
    reviewBody:`I am a 75 year old widow, living alone in Norfolk, the
    Team at GET have been friendly, helpful and explained everything in plain English, I have now saved
    around 60% of my electricity bill with Solar and storage.`,
    userName:`Christine Neal`
    }
]