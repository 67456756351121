import React, { useState, useEffect, useMemo } from "react"
import Img from "gatsby-image"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import flatten from 'lodash.flatten';
import * as FiveStars from "~/vectors/five_stars.inline.svg"
import * as FourStars from "~/vectors/four_stars.inline.svg"
import * as ThreeStars from "~/vectors/three_stars.inline.svg"
import ReviewsCont  from "./reviewsSource"

import "./styles.scss"
import { imageNodesFilter } from "~/utils"

import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Col4 from "~/components/grid/Col4";

{/* <ThreeStars className="threeStars" style={{ marginTop: '1em', paddingRight: '2em', paddingLeft: '2em', maxWidth:'35%' }} />
<FourStars className="fourStars" style={{ marginTop: '1em', paddingRight: '2em', paddingLeft: '2em', maxWidth:'35%' }} />
<FiveStars className="fiveStars" style={{ marginTop: '1em', paddingRight: '2em', paddingLeft: '2em', maxWidth:'35%' }} /> */}


const getSlides = () => {

  const iterator = [...Array(10)];

  return [
    ...iterator.map(() => {
      return ReviewsCont.reviewsCont.map((review) => {

        // //console.log(starImages[review.rating])
        return (
          <div style={{ padding: '3em' }} className={review.rating} >
            <p style={{ color: '#70b33b', lineHeight: '1.3', fontSize: '1.2em', marginBottom: '.8em' }}>
              {review.reviewTitle}
            </p>
            <p style={{ lineHeight: '1.3', marginBottom: '.8em', marginTop: '0px', textOverflow: 'ellipsis' }}>
              {review.reviewBody.slice(0, 300)}...
            </p>
            <p style={{ fontSize: '.8em', marginTop: '0px', fontWeight: 'bold', color: '#051c3f', marginBottom: '0px' }}>
            {review.userName} 
            </p>
            <p style={{marginTop:'0px',color:'#E8B90E', fontSize: '2.5em'}}>
            {review.rating}
            </p>
           
          </div>

        );
      })
    })
  ]
}

const ReviewsSlider = () => {

  return (
   

      <div className="reviews-slider" >
        <Carousel
          autoPlay={true}
          interval={2000}
          showThumbs={false}
          infiniteLoop={true}
          centerMode={true}
          centerSlidePercentage={100}
          showArrows={true}
          showStatus={false}
          showIndicators={false}
        >
          {getSlides()}
        </Carousel>
      </div>
   
  )
}

export default ReviewsSlider
