export interface ChildMenuData {
    title: string;
    to?: string;
    children?: ChildMenuData[];
}

export interface MenuData {
    title: string;
    children: ChildMenuData[];
}

export const forYourHome: MenuData = {
    title: 'For your Home',
    children: [
      {
        title: 'Domestic solar',
        to: '/'
      },
      {
        title: 'Contact us',
        to: '/contact-us'
      }
    ]
}

export const forYourBusiness: MenuData = {
    title: 'For your Business',
    children: [
      {
        title: 'Commerial Solutions',
        to: '/for-your-business'
      },
      {
        title: 'Contact us',
        to: '/contact-us'
      }
      // ,
      // {
      //   title: 'Services',
      //   children: [
      //     {
      //       title: 'Industrial & Commercial',
      //       to: '/service/industrial-commercial-solutions/',
      //     },
      //     {
      //       title: 'Operation & Maintenance',
      //       to: '/service/operation-maintenance/',
      //     },
      //     {
      //       title: 'Technical design',
      //       to: '/service/technical-design/',
      //     },
      //     {
      //       title: 'Asset Management',
      //       to: '/service/asset-management/',
      //     }
      //   ],
      // }
    ]
}

// export const forYourCommunity: MenuData = 
//     {
//     title: 'For your Community',
//     children: [
//       {
//         title: 'Community Energy',
//         to: '/for-your-community'
//       },
//       {
//         title: 'PPAs',
//         to: '/ppa'
//       },
//       {
//         title: 'Church Buying',
//         to: '/twobuytwo'
//       },
//       {
//         title: 'Solar Together',
//         children: [
//             {
//                 title: 'The scheme',
//                 to: '/solar-together',
//               },
//               {
//                 title: 'FAQs',
//                 to: '/solar-together-faq',
//               }
//         ],
//       }
//     ]
// }

export const forYourCommunity: MenuData = 
    {
    title: 'For your Community',
    children: [
      {
        title: 'Community Energy',
        to: '/for-your-community'
      },
      {
        title: 'PPAs',
        to: '/ppa'
      },
      {
        title: 'Church Buying',
        to: '/twobuytwo'
      },
      {
        title: 'Contact us',
        to: '/contact-us'
      },
    ]
}

export const company: MenuData = {
    title: 'Company',
    children: [
      {
        title: 'About Us',
        to: '/about/'
      },
      {
        title: 'Careers',
        to: '/careers/'
      },
      {
        title: 'Case Studies',
        to: '/projects/'
      },
      {
        title: 'Blog',
        to: '/blog'
      },
      {
        title: 'Certifications',
        to: '/certifications'
      }
    ]
}

export const products: MenuData = {
  title: 'Products',
  children: [
    {
      title: 'Why Solar',
      to: '/why-solar/'
    },
    {
      title: 'Solar Panels',
      to: '/panels-info/'
    },
    {
      title: 'Mounting Kits',
      to: '/mounting-info/'
    },
    {
      title: 'Inverters',
      to: '/inverters-info/'
    },
    {
      title: 'Battery Storage',
      to: '/batteries-info/'
    },
    {
      title: 'Accessories',
      to: '/accessories-info/'
    }
  ]
}

export const solarTogether: MenuData = {
  title: 'Solar Together',
  children: [
    {
      title: 'The scheme',
      to: '/solar-together'
    },
    {
      title: 'Solar Together FAQs',
      to: '/solar-together-faq'
    }
  ]
}

export const infoCenter: MenuData = {
  title: 'Info Center',
  children: [
    {
      title: 'After Sales Process',
      to: '/aftersales/'
    },
    {
      title: 'SEG',
      to: '/seg/'
    },
    {
      title: 'Warranties',
      to: '/domestic-warranties/'
    },
    {
      title: 'Support and FAQs',
      to: '/faq'
    }
  ]
}

