import markdownNodesFilter from "./markdownNodesFilter"
import imageNodesFilter from "./imageNodesFilter"

type Nullable<T> = T | null;

// TODO: This type is not complete, find out all the props and map. #fuckgeorge
interface CMSProduct {
    fields: {
        slug: string;
    };
    fileAbsolutePath: string;
    frontmatter: any;
}

const normalise = (value: string): string => value && value.trim().toLowerCase() || '';

export const filterProductsBy = (products: CMSProduct[], key: string, value: any) => {
  return products.filter(product => normalise(product.frontmatter[key]).includes(normalise(value)));
}

export const filterProductsByAny = (products: CMSProduct[], keys: string[], filter: any) => {
    let filteredProducts = [];

    keys.forEach(key => {
        filteredProducts = filteredProducts.concat(filterProductsBy(products, key, filter));
    });

    return [...new Set(filteredProducts)];
}


export { markdownNodesFilter, imageNodesFilter }
