import React, { useContext } from "react"

import BlockCTA from "~/components/configurable/BlockCTA"
import Col3 from "~/components/grid/Col3"
import Heading from "~/components/configurable/Heading"

import Logo from "~/vectors/logo.inline.svg"

import { SitemapItem } from "~/hooks/useSitemap"
import { Link } from "gatsby"

import "./styles.scss"
import Icon from "~/components/olc-framework/Icon"

import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"

interface FooterProps {
  sitemap: SitemapItem[];
  isSolarTogether: any;
}

const Footer: React.FC = () => {
  const { customerType } = useContext(CustomerTypeContext);

  const isBusiness = React.useMemo(() => customerType === "commercial", [customerType]);
  const isDomestic = React.useMemo(() => customerType === "domestic", [customerType]);
  const isSolarTogether = React.useMemo(() => customerType === "solartogether", [customerType]);

  return (
    <footer className="footer">
      <div className="footer__top">
        
          {!isBusiness && (
          <div className="container">
            <BlockCTA className="visible-xs" url="/quote">
              Get a Quote Today
            </BlockCTA>
            <div className="footer__logo">
              <Logo />
            </div>
            <BlockCTA className="hidden-xs" url="/quote" right>
              Get a Quote Today
            </BlockCTA>
          </div>
          )}

          {isBusiness && (
          <div className="container">
            <BlockCTA className="visible-xs" url="/quote-commercial">
              Get a Quote Today
            </BlockCTA>
            <div className="footer__logo">
              <Logo />
            </div>
            <BlockCTA className="hidden-xs" url="/quote-commercial" right>
              Get a Quote Today
            </BlockCTA>
          </div>
          )}
        
      </div>
      <div className="footer__middle">
        <div className="container">
          <div className="row" style={{ marginLeft: 0 }}>
            <div
              // style={{backgroundColor:"red"}} 
              className="footer__middle__column">
              <div>
                <Heading className="footer__column-heading">Get in touch</Heading>
              </div>
              <div className="footer__item">
                <span>
                  {isSolarTogether && (
                    <>
                      <a
                        href="tel:02039898987"
                        style={{
                          position: "relative",
                          paddingLeft: "34px",
                          display: "block",
                        }}
                      >
                        <Icon alias="phone" />
                        020 3989 8987
                      </a>
                    </>
                  )}
                  {isBusiness && (
                    <>
                      <a
                        href="tel:01920452175"
                        style={{
                          position: "relative",
                          paddingLeft: "34px",
                          display: "block",
                        }}
                      >
                        <Icon alias="phone" />
                        01920 452 175
                      </a>
                    </>
                  )}
                  {isDomestic && (
                    <>
                      <a
                        href="tel:02039954422"
                        style={{
                          position: "relative",
                          paddingLeft: "34px",
                          display: "block",
                        }}
                      >
                        <Icon alias="phone" />
                        020 3995 4422
                      </a>
                    </>
                  )}

                  <a
                    href="/contact-us"
                    style={{
                      position: "relative",
                      paddingLeft: "34px",
                      display: "block",
                      marginTop: "10px"
                    }}
                  >
                    <Icon alias="email" />
                    Message us
                  </a>
                </span>
              </div>





              <div style={{ marginTop: 30 }}>
                <Heading className="footer__column-heading-sub">Visit us</Heading>
              </div>
              <div className="footer__item">

                <span style={{ position: "relative", paddingLeft: "34px", lineHeight: 1.5 }}>
                  <Icon alias="pin" />
                  8 Peerglow Centre,
                  <br />
                  Marsh Lane,
                  <br />
                  Ware,
                  <br />
                  Hertfordshire
                  <br />
                  SG12 9QL
                </span>
              </div>
              <div className="footer__item">
                <div className="footer__item__sm-icons" style={{justifyContent:"left !important"}}>
                  <button>
                      <img src={require('../../../images/sm_icons/fb.svg')} alt="facebook"  onClick={()=> window.open("https://www.facebook.com/greenenergytogether/", "_blank")} />
                  </button>
                  <button>
                      <img src={require('../../../images/sm_icons/linkedin.svg')} alt="facebook"   onClick={()=> window.open("https://www.linkedin.com/company/get-uk", "_blank")} />
                  </button>
                  <button>
                      <img src={require('../../../images/sm_icons/insta.svg')} alt="facebook"   onClick={()=> window.open("https://www.instagram.com/green.energy.together/", "_blank")} />
                  </button>
                  <button>
                      <img src={require('../../../images/sm_icons/twitter.svg')} alt="facebook"   onClick={()=> window.open("https://twitter.com/GET_UK_solar", "_blank")} />
                  </button>
                </div>
              </div>

            </div>
            <div
              // style={{backgroundColor:"orange"}}  
              className="footer__middle__column">
              <div>
                <Heading className="footer__column-heading">Domestic</Heading>
              </div>
              <Link className="footer__item" to="/#process"  >
                Domestic Process
              </Link>
              <Link className="footer__item" to="/domestic-warranties/" >
                Warranties
              </Link>
              <Link className="footer__item" to="/service/case-studies">
                Case Studies
              </Link>
              <Link className="footer__item" to="/get-terms">
                Terms and Conditions
              </Link>
                {/* <div style={{ marginTop: 30 }}>
                  <Heading className="footer__column-heading-sub">Subheading</Heading>
                </div>
                <Link className="footer__item" to="/service/technical-design">
                  Lorem ipsum
                </Link>
                <Link className="footer__item" to="/service/technical-design">
                  Lorem ipsum
                </Link>
                <Link className="footer__item" to="/service/technical-design">
                  Lorem ipsum
                </Link> */}
            </div>
            <div
              // style={{backgroundColor:"blue"}}  
              className="footer__middle__column">
              <div>
                <Heading className="footer__column-heading">Businesses</Heading>
              </div>
              <Link className="footer__item" to="/inverters-info">
                Commercial Quote
              </Link>
              <Link className="footer__item" to="/for-your-business#process" >
                Business Processes 
              </Link>
              <Link className="footer__item" to="/projects">
                Case Studies 
              </Link>
                {/* <div style={{ marginTop: 30 }}>
                  <Heading className="footer__column-heading-sub">Subheading</Heading>
                </div>
                <Link className="footer__item" to="/service/technical-design">
                  Lorem ipsum
                </Link>
                <Link className="footer__item" to="/service/technical-design">
                  Lorem ipsum
                </Link>
                <Link className="footer__item" to="/service/technical-design">
                  Lorem ipsum
                </Link> */}
            </div>
            <div
              // style={{backgroundColor:"magenta"}}  
              className="footer__middle__column">
              <div>
                <Heading className="footer__column-heading">General</Heading>
              </div>
              <Link className="footer__item" to="/solar-together/">
                Solar Together 
              </Link>
              <Link className="footer__item" to="/twobuytwo/">
                Church Buying
              </Link>
              <Link className="footer__item" to="/ppa/" style={{lineHeight: 1.3, marginTop:12}} >
              Power Purchase Agreements (PPA’s) 
              </Link>
              <Link className="footer__item" to="/emergency-workers">
              Emergency Workers
              </Link>
              <Link className="footer__item" to="/refer-a-friend">
               Refer a friend
              </Link>
              <Link className="footer__item" to="/promotions">
              Promotions
              </Link>
                {/* <div style={{ marginTop: 30 }}>
                  <Heading className="footer__column-heading-sub">Subheading</Heading>
                </div>
                <Link className="footer__item" to="/service/technical-design">
                  Lorem ipsum
                </Link>
                <Link className="footer__item" to="/service/technical-design">
                  Lorem ipsum
                </Link>
                <Link className="footer__item" to="/service/technical-design">
                  Lorem ipsum
                </Link> */}
            </div>
            <div
              // style={{backgroundColor:"green"}}  
              className="footer__middle__column">
              <div>
                <Heading className="footer__column-heading">Info Centre </Heading>
              </div>
              <Link className="footer__item" to="/why-solar/">
                Why Solar 
              </Link>
              <Link className="footer__item" to="/seg/">
                SEG Application 
              </Link>
              <Link className="footer__item" to="/aftersales#app-monitoring"  >
                App Monitoring 
              </Link>
              <Link className="footer__item" to="/faq/">
                FAQs
              </Link>
              <Link className="footer__item" to="/solar-terminology/">
                Solar Terminology
              </Link>
              <Link className="footer__item" to="/covid-19/">
                Covid 
              </Link>
              <Link className="footer__item" to="/privacy/">
                Privacy Policy 
              </Link>
              <Link className="footer__item" to="/careers/">
                Careers
              </Link>
                {/* <div style={{ marginTop: 30 }}>
                  <Heading className="footer__column-heading-sub">Subheading</Heading>
                </div>
                <Link className="footer__item" to="/service/technical-design">
                  Lorem ipsum
                </Link>
                <Link className="footer__item" to="/service/technical-design">
                  Lorem ipsum
                </Link>
                <Link className="footer__item" to="/service/technical-design">
                  Lorem ipsum
                </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="container">
          <span className="u-font--xsmall u-font--muted">
            <a href="/site-map" style={{textDecoration:"underline"}} >Sitemap</a>
          </span>
          <span className="u-font--xsmall u-font--muted left">
            &copy; GET - Green Energy Together - 2020
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
