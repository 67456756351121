import React, { FunctionComponent } from "react"

export interface ColProps {
  columns?: number
  indent?: boolean
  squidge?: boolean
  style?: object
  className?: string
  ref?: any
}

const Col: FunctionComponent<ColProps> = ({
  columns,
  indent,
  squidge,
  style,
  children,
  className,
  ref,
}) => {
  return (
    <div
      className={`col-${columns} ${indent ? "u-layout--indent" : ""} ${
        squidge ? "u-layout--squidge" : ""
      } ${className || ""}`}
      style={style}
      ref={ref}
    >
      {children}
    </div>
  )
}

export default Col
