import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import flatten from 'lodash.flatten';

import "./styles.scss"
import { imageNodesFilter } from "~/utils"

import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const LOGO_MAP = {
  ac: require('../../../images/cert/ac.jpg'),
  elmapp: require('../../../images/cert/elmapp.jpg'),
  elmqua: require('../../../images/cert/elmqua.jpg'),
  epvs: require('../../../images/cert/epvs.jpg'),
  iso9001: require('../../../images/cert/iso9001.jpg'),
  iso14001: require('../../../images/cert/iso14001.jpg'),
  is45001: require('../../../images/cert/iso45001.jpg'),
};

const getSlides = () => {
  const iterator = [...Array(10)];

  return [
    ...iterator.map(() => {
      return Object.keys(LOGO_MAP).map((logoKey) => {
        const logoUrl = LOGO_MAP[logoKey];
  
        return (
          <div>
            <img style={{padding:30}} alt={logoKey} src={logoUrl} />
          </div>
        );
      })
    })
  ]
}

const Certificates = ({ imageNodes }) => {
  const [mobileImage, setMobileImage] = useState(undefined)
  const [desktopImage, setDesktopImage] = useState(undefined)

  useEffect(() => {
    setMobileImage(imageNodesFilter(imageNodes, "certificates-mobile.png"))
    setDesktopImage(imageNodesFilter(imageNodes, "certificates.png"))
  }, [imageNodes])

  return (
    <Block className="certificates" highlightColour="blue">

        <div className="container">
        <Heading level={2} underlined>
        Quality, expertise, competence, trustworthiness...
        </Heading>
        <p>


            At Green Energy Together we aim to provide the highest level of service to our customers and internal quality controls to ensure that all our products and services are delivered at the highest standard. Our industry-recognised national and international certifications say it all.
        </p>


      </div>
              
      <Carousel 
        className="hidden-xs" 
        autoPlay 
        interval={3000} 
        showThumbs={false} 
        infiniteLoop={true} 
        centerMode={true} 
        centerSlidePercentage={20} 
        showArrows={true} 
        showStatus={false} 
        showIndicators={false}
      >
          { getSlides() }
        </Carousel>


      <div className="container">

     
        {!!mobileImage && (
          <Img
            className="visible-xs"
            fluid={mobileImage.fluid}
            alt="Certificate logos"
          />
        )}

      </div>



    </Block>
  )
}

export default Certificates
